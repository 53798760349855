body{
    background: #fff;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    font-family: "proxima-nova";
    color: $gunpowder;
}
h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-family: "proxima-nova";
    text-transform: inherit;
}
h1{
    // font-size: responsive 50px 60px;
    // font-range: 420px 1280px;
}
p{
    font-size: responsive 14px 16px;
    font-range: 420px 1280px;
    font-family: "proxima-nova";
    color: $gunpowder;
}
ul, ol{
    list-style: none;
    margin: 0;
    padding: 0;
}
a{
    line-height: 1em;
    color: $turquoise;
}
label{
    font-family: "proxima-nova"
}
button, input, select, table, textarea{
    color: $gunpowder;
}
