// Colors
$white: #fff;
$black: #000;
$darkslategrey: #474753;
$dimgrey: #4C3B4D;
$gunpowder: #4D4D5B;
$gainsboro: #D8D8D8;
$turquoise: #39CEBD;
$turquoise-dark: darken($turquoise, 10%);
$darkgray: #A4A3AF;
$whitesmoke: #f2f2f4;
$hotpink: #FF5794;
$darkgray-nav: #AEADB8;
$gainsboro: #E4E4E7;

// Font SVG Icons
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ucxa7m');
  src:  url('../fonts/icomoon.eot?ucxa7m#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ucxa7m') format('truetype'),
    url('../fonts/icomoon.woff?ucxa7m') format('woff'),
    url('../fonts/icomoon.svg?ucxa7m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-box-cart-add:before {
  content: "\e905";
}
.icon-box-cart:before {
  content: "\e906";
}
.icon-cards:before {
  content: "\e907";
}
.icon-minus:before {
  content: "\e908";
}
.icon-pillows:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e90a";
}
.icon-user:before {
  content: "\e90b";
}
.icon-wishlist-add:before {
  content: "\e90c";
}
.icon-wishlist:before {
  content: "\e90d";
}
.icon-logo .path1:before {
  content: "\e90e";
  color: rgb(77, 77, 91);
}
.icon-logo .path2:before {
  content: "\e90f";
  margin-left: -2.3427734375em;
  color: rgb(65, 212, 197);
}

// Extra small screen / phone
$screen-xs: 480px;

// Small screen / tablet
$screen-sm: 768px;

// Medium screen / desktop
$screen-md: 992px;

// Large screen / wide desktop
$screen-lg: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);

// respond-to()
// ---------------------------------------------------------------------

@mixin respond-to($media) {
    @if $media == mobile {
		@media (max-width: $screen-xs-max) { @content }
	}
	@else if $media == tablet {
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) { @content }
	}
	@else if $media == laptop {
		@media (min-width: $screen-md) and (max-width: $screen-md-max) { @content }
	}
	@else if $media == desktop {
		@media (min-width: $screen-lg) { @content }
	}
  @else {
    @media screen and (min-width: $media + px) { @content; }
  }
}
