/**
* -----------------------------------------------------------------------
*                                Navigation
* -----------------------------------------------------------------------
*/
.menu-wrap {
    box-shadow: 0 1px 0 0 $whitesmoke;
}

.main-nav {
    display: flex;
    align-items: center;
    height: auto;
    bottom: 0;
    &.middle {
        left: 0;
        right: 0;
        padding: 0 120px;
    }
    h1, ul li {
        display: inline-block;
    }
    li a {
        font-family: "proxima-nova";
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $gunpowder;
    }
    ul {
        &.left li,
        &.right li {
            display: flex;
            align-items: center;
            max-height: 31px;
        }
        &.left li {
            margin-right: 16px;
        }
        &.right li {
            margin-left: 16px;
        }
    }
    .navbar-brand {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img {
            height: 35px;
        }
    }
    [class*="icon-"] {
        font-size: 26px;
    }
    .icon-logo {
        font-size: 34px;
    }
    .left.show-for-large-up {
        order: 1;
    }
    .right.show-for-large-up {
        order: 3;
        margin-left: auto;
    }
    #header-nav {
        order: 2;
        border-bottom: 0;
    }
    .nav-primary {
        margin-top: 0;
        border-left: 1px solid $whitesmoke;
        padding-left: 15px;
        > li {
            a {
                display: inline-block;
                overflow: hidden;
                padding: 0;
                display: flex;
                align-items: center;
                margin-top: 5px;
                text-transform: capitalize;
                &:hover, &:focus, &:active {
                    color: $turquoise;
                }
                &::before {
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 26px;
                    float: left;
                    color: $gunpowder;
                    text-indent: 0;
                    margin-top: 3px;
                    margin-right: 5px;
                }
            }
            &:nth-child(1) {
                a::before {
                    content: "\e909";
                }
            }
            &:nth-child(2) {
                margin-left: 15px;
                a::before {
                    content: "\e907";
                }
            }
            &:nth-child(3) {
                display: none;
            }
            &:nth-child(n+4){
                margin-left: 15px;
                a::before{
                    content: "\e907";
                }
            }
        }
    }
    .skip-cart {
        .icon {
            margin-right: 0;
            background: none;
            &::before {
                margin-top: 8px;
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 26px;
                float: left;
                text-indent: 0;
                color: $gunpowder;
                content: "\e906";
            }
        }
        .label, .count {
            display: none;
        }
    }
    #header-search {
        position: relative;
        width: 100%;
        top: 5px;
    }
    #search_mini_form {
        &:focus, &:active {
            #search {
                background: none;
            }
        }
        .search-button:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 26px;
            float: left;
            text-indent: 0;
            color: $gunpowder;
            content: "\e90a";
            background: none;
        }
    }
    #search {
        border: none;
        box-shadow: none;
        min-width: 220px;
    }

}

.tab-bar {
    height: 65px;
    display: flex;
    align-items: center;
    background: $white;
    .right-small {
        border: none;
        height: 100%;
    }
    .menu-icon {
        height: 100%;
        span::after {
            box-shadow: 0 0 0 1px $gunpowder,
            0 7px 0 1px $gunpowder,
            0 14px 0 1px $gunpowder;
            width: 1.5rem;
            left: -0.09375rem;
        }
    }
}

// Header banner
.header-banner {
    background-color: white;
    border-bottom: 1px solid $whitesmoke;

    @media only screen and (max-width: 770px) {
        height: 320px;
        overflow-y: auto;
    }

    &__section,
    &__container {
        display: flex;
        flex-wrap: wrap;
    }
    &__container {
        padding: 12px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
    &__container--top {
        .header-banner__section {
            padding: 8px 12px;

            @media only screen and (max-width: 770px){
                flex-direction: column;
            }
            @media only screen and (min-width: 771px){
            }
        }
        .header-banner__text {
            @media only screen and (max-width: 770px){
                text-align: center;
            }
        }
    }
    &__container--bottom {
        @media only screen and (min-width: 1200px) {
            max-width: 75%;
            margin: auto;
        }
        p {
            display: inline-flex;
            margin-left: 8px !important;
            margin-right: 8px !important;

            @media only screen and (max-width: 770px){
                display: block;
            }
        }
    }
    &__section {
        align-items: center;
    }
    &__image {
        @media only screen and (max-width: 770px){
            margin-top: 4px;
            margin-bottom: 4px;
        }
        @media only screen and (min-width: 771px){
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    h4, p {
        font-family: "Raleway", "Helvetica Neue", Verdana, Arial, sans-serif;
        color: #1F292E;
    }
    p {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
    }
}


.right-off-canvas-menu {
    background: $white;
}

ul.off-canvas-list {
    margin-top: 28px;
    .nav-primary li {
        &:nth-child(1) {
            a::before {
                content: "\e909";
            }
        }
        &:nth-child(2) {
            a::before {
                content: "\e907";
            }
        }
        &:nth-child(3) {
            display: none;
        }
        &:nth-child(n+4){
            a::before{
                content: "\e907";
            }
        }
    }
    #header-account li a, .nav-primary a.level0 {
        color: $gunpowder;
        text-transform: capitalize;
        padding: 6px 0;
    }
    li {
        a {
            padding: 11px 0;
            color: $gunpowder;
            border-bottom: 1px solid #cccccc;
            text-align: center;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-top: 5px;
            justify-content: center;
            width: 100%;
            [class*="icon-"] {
                font-size: 26px;
            }
            &:hover, &:focus, &:active {
                color: $turquoise;
            }
            &::before {
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 26px;
                float: left;
                color: $gunpowder;
                text-indent: 0;
                margin-top: 3px;
                margin-right: 5px;
            }
        }
    }
    .skip-cart {
        .icon {
            margin-right: 0;
            background: none;
            &::before {
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 26px;
                float: left;
                text-indent: 0;
                color: $gunpowder;
                content: "\e906";
            }
        }
        .label, .count {
            display: none;
        }
    }
    #header-search {
        position: relative;
        width: 100%;
        top: 5px;
    }
    #search_mini_form {
        &:focus, &:active {
            #search {
                background: none;
            }
        }
        .search-button:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 26px;
            float: left;
            text-indent: 0;
            color: $gunpowder;
            content: "\e90a";
            background: none;
        }
    }
    #search {
        border: none;
        box-shadow: none;
        min-width: 220px;
    }
    .skip-content {
        display: block;
    }
    .block-subtitle, .empty {
        display: none;
    }
    .search-autocomplete:before {
        content: none;
    }
    #search_mini_form .search-button {
        top: 32px;
        right: -10px;
    }
    .block, .col-left-first {
        margin-bottom: 0;
    }
    nav#nav {
        margin-bottom: 30px;
    }
}

.off-canvas-wrap {
    &.open {
        position: relative !important;
        top: auto;
        width: 100%;
        z-index: 99;
        left: auto;
    }
    &.move-left {
        position: fixed;
    }
}

.vertical-line-left {
    padding-left: 15px;
    border-left: 1px solid $whitesmoke;
}

/**
* -----------------------------------------------------------------------
*                           Foundation Overwrite
* -----------------------------------------------------------------------
*/
.label {
    background: transparent;
    font-family: "proxima-nova";
    font-size: 10px;
    line-height: 12px;
    color: $darkgray;
}

table {
    border: none;
    tr.even, tr.alt, tr:nth-of-type(even) {
        background: transparent;
    }
}

ul, ol, dl {
    line-height: 1.2em;
}

body.f-topbar-fixed {
    padding: 0;
}

.button {
    text-transform: inherit;
    padding: 12px 18px;
    &.round {
        background-color: $turquoise;
        border-radius: 25px;
        border: 1px solid transparent;
    }
}

/**
* -----------------------------------------------------------------------
*                            Magento Overwrite
* -----------------------------------------------------------------------
*/
.button, .cart-table .product-cart-actions .button, #co-shipping-method-form .buttons-set .button, .footer .button {
    background-color: $turquoise;
    border-radius: 25px;
    border: 1px solid transparent;
    span {
        text-transform: inherit;
    }

    &:hover {
        background-color: $turquoise-dark;
    }
}

.footer-container {
    .footer {
        .links {
            a:hover {
                color: $turquoise;
            }
        }
    }
}

.button2 span, .button2 span span {
    text-decoration: none;
}

.button2 span:hover, .button2 span span:hover {
    color: $white;
    background-color: $turquoise-dark;
}

.block-title h2, .block-title h3, .block-title strong, .footer, .footer button, form .form-instructions,
.button, .cart-table .product-cart-actions .button, #co-shipping-method-form .buttons-set .button, .footer .button, small {
    font-family: "proxima-nova";
}

.fieldset p.required {
    float: none;
}

.block-title h2, .block-title h3, .block-title strong,
.footer .block-title, .footer address {
    color: $turquoise;
    font-weight: 500;
}

@media only screen and (max-width: 770px) {
    .block-layered-nav .block-content {
        .block-subtitle--filter {
            background-color: $turquoise;
        }
        dt.last {
            display: none;
        }

        & > dl > dd {
          display: block;
      }
    }
}

@media only screen and (max-width: 599px) {
    .footer-container {
        .footer {
            text-align: center;

            .links {
                width: 100%;
            }
        }
    }
}

.links .block-title strong {
    font-size: 18px;
    text-transform: none;
}

.cms-page-view .std h1, .cms-no-route .std h1,
.cms-page-view .std p, .cms-no-route .std p,
.cms-page-view .std li, .cms-no-route .std li,
form .legend, body, button, input, select, table, textarea,
.cms-page-view .std h2, .cms-no-route .std h2,
.pager .amount {
    font-family: "proxima-nova";
    color: $gunpowder;
}

.page-sitemap .sitemap {
    font-size: 16px;
}

.btn-remove, .btn-previous {
    width: 100%;
    height: 30px;
    font: 0/30px "proxima-nova";
    margin-bottom: 10px;

    &::after {
        margin-left: 15px;
    }
}

span.value {
    font-family: "proxima-nova";
}

/**
* -----------------------------------------------------------------------
*                               Layout Parts
* -----------------------------------------------------------------------
*/
.row-100 {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

.center-button {
    margin: 0 auto;
}

.cms-home, .catalog-category-view, .catalog-product-view {
    .main-container {
        max-width: 100%;
        padding: 0;
    }
}

/**
* -----------------------------------------------------------------------
*                               Global Icons
* -----------------------------------------------------------------------
*/
.icon.arrow-right,
.icon.wishlist-add {
    font-size: 8px;
}

/**
* -----------------------------------------------------------------------
*                                Home Page
* -----------------------------------------------------------------------
*/
.home-intro {
    text-align: center;
    h1, .page-title, a {
        color: $white;
    }
    h1 {
        font-family: "proxima-nova";
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 32px;
        font-size: responsive 34px 64px;
        font-range: 420px 1280px;
        text-transform: inherit;
        @include respond-to(laptop) {
            padding: 0 40px;
        }
        span {
            font-style: italic;
        }
    }
    p {
        font-family: "chaparral-pro";
        font-size: responsive 22px 32px;
        font-range: 420px 1280px;
        line-height: 32px;
        color: $white;
        margin-bottom: 48px;
    }
    .button {
        font-family: "proxima-nova";
        font-size: 14px;
        line-height: 16px;
        width: 166px;
        font-weight: 500;
    }
    .page-title {
        font-family: "chaparral-pro";
        font-size: 32px;
    }
    &__box {
        box-shadow: inset 0 0 0 500px rgba(75, 26, 44, .25);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .columns {
        padding: 0;
    }
}

.home-features {
    overflow: hidden;
    @include respond-to(tablet) {
        margin-top: 15px;
    }
    @include respond-to(mobile) {
        margin-top: 15px;
    }
    .columns {
        padding: 0;
    }
    .box-wrapper {
        display: flex;
        justify-content: center;
        @include respond-to(laptop) {
            flex-wrap: wrap;
        }
        @include respond-to(tablet) {
            flex-direction: column;
            align-items: center;
        }
        @include respond-to(mobile) {
            flex-direction: column;
            align-items: center;
        }
    }
    .box-feature {
        position: relative;
        margin: 15px 15px 17px 0;
        max-width: 340px;
        height: 340px;
        max-width: 100%;
        width: calc(100% / 4);
        @include respond-to(tablet) {
            width: calc(100% / 2);
        }
        @include respond-to(mobile) {
            margin: 0 0 17px;
            max-width: 510px;
            width: 100%;
        }
        & > a {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @include respond-to(mobile) {
                left: 15px;
                right: 15px;
            }
        }
        &:first-child {
            margin-left: 15px;
            @include respond-to(tablet) {
                margin-left: 0;
            }
            @include respond-to(mobile) {
                margin: 0 0 17px;
            }
        }
        &:last-child {
            margin-left: 0;
        }
        a.title, .price {
            color: $gunpowder;
        }
        a.title {
            &:hover, &:focus {
                color: $gunpowder;
            }
            margin-bottom: 0;
            font-size: 12px;
            font-weight: bold;
        }
        .price {
            font-size: 16px;

            .old-price {
                .price {
                    font-size: 14px;
                }
            }
        }
        img {
            height: 80%;
            max-width: none;
            width: auto;
        }
        &__text {
            padding: 12px;
            width: 80%;
            text-align: center;
        }
        &__button {
            position: absolute;
            right: 8px;
            bottom: 30px;
            background-color: transparent;
            padding: 0;
            margin: 0;
            [class*='icon-'] {
                font-size: 26px;
                color: $gunpowder;
                line-height: 10px;
            }
        }
    }
}

.more-info {
    margin-bottom: 65px;
    .box-wrapper {
        display: flex;
        @include respond-to(tablet) {
            flex-direction: column;
        }
        @include respond-to(mobile) {
            flex-direction: column;
        }
    }
    .box-feature {
        min-height: 330px;
        @include respond-to(mobile) {
            min-height: 350px;
        }
        &.bigger, &.smaller {
            position: relative;
            display: flex;
        }
        &.bigger {
            background-color: $turquoise;
            flex: 2.435;
            margin: 0 0 0 15px;
            @include respond-to(tablet) {
                margin: 0 15px 15px;
            }
            @include respond-to(mobile) {
                margin: 0 15px 15px;
            }
            img {
                height: 241px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            .description {
                color: $white;
                @include respond-to(1350) {
                    padding-right: 0;
                    max-width: 625px;
                }
                .dash-icon {
                    font-size: 40px;
                }
            }
        }
        &.smaller {
            flex: 1;
            margin: 0 15px;
            overflow: hidden;
            width: 100%;
            @include respond-to(tablet) {
                width: calc(100% - 30px);
            }
            @include respond-to(mobile) {
                width: calc(100% - 30px);
            }
            .description {
                color: $black;
                @include respond-to(1350) {
                    padding-right: 0;
                    max-width: 375px;
                }
            }
            img {
                background: $hotpink;
                height: 100%;
                max-width: none;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }
        }

        &__text.spaced {
            z-index: 50;
            position: absolute;
            top: 35px;
            left: 95px;
            padding: 10px 0;
            @include respond-to(tablet) {
                left: 35px;
            }
            @include respond-to(mobile) {
                left: 35px;
            }
            .title {
                font-family: "proxima-nova";
                font-size: 18px;
                font-weight: bold;
                line-height: 18px;
                color: $dimgrey;
                text-transform: uppercase;
                letter-spacing: .03em;
                margin-bottom: 30px;
            }
            .description {
                font-family: "proxima-nova";
                font-size: responsive 24px 36px;
                font-range: 420px 1280px;
                font-weight: bold;
                line-height: 48px;
                padding-right: 30%;
                @include respond-to(1200) {
                    padding-right: 10%;
                }
                @include respond-to(laptop) {
                    padding-right: 10%;
                }
                @include respond-to(tablet) {
                    padding-right: 35px;
                }
                @include respond-to(mobile) {
                    padding-right: 35px;
                    line-height: 28px;
                }
            }
        }
        a:last-of-type {
            position: absolute;
            right: 115px;
            bottom: 55px;
            display: flex;
            font-family: "proxima-nova";
            font-size: 14px;
            font-weight: bold;
            text-align: right;
            line-height: 24px;
            color: $gunpowder;
            font-size: 14px;
            letter-spacing: 0.02em;
            z-index: 50;
            @include respond-to(laptop) {
                bottom: 25px;
            }
            @include respond-to(mobile) {
                bottom: 35px;
                right: 35px;
            }
            &::after {
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 26px;
                color: $gunpowder;
                content: "\e903";
                position: relative;
                left: 8px;
            }
            [class*="icon-"] {
                font-size: 26px;
                color: $gunpowder;
                margin-left: 8px;
            }
        }
    }
    .arrow-right {
        margin-left: 8px;
    }
}

.homepage-intro-carousel {
    .owl-item img {
        max-height: 918px;
        @include respond-to(mobile) {
            object-fit: cover;
            height: 100%;
            min-height: 330px;
        }
    }
}

/**
* -----------------------------------------------------------------------
*                            Product List Page
* -----------------------------------------------------------------------
*/
.choose-product {
    padding: 35px 15px 0;
    .page-title.category-title {
        display: none;
        font-family: "proxima-nova";
        font-size: 32px;
        font-weight: bold;
        color: $black;
        @include respond-to(mobile) {
            display: block;
            text-align: center;
        }
    }
    .col-left {
        width: 18.1%;
        float: left;
        position: relative;
        min-height: 1px;
        padding: 0;
        @include respond-to(tablet) {
            width: 30%;
        }
        dt {
            display: none;
            @include respond-to(mobile) {
                display: block;
            }
        }
        .block-title {
            margin-bottom: 0;
            h1, span {
                color: $black;
                font-family: "proxima-nova";
                font-size: 32px;
                font-weight: bold;
                text-transform: inherit;
                line-height: 32px;
            }
        }
        h1 {
            margin-top: 0;
            margin-bottom: 32px;
            font-family: "proxima-nova";
            font-size: 32px;
            line-height: 32px;
        }
        .block-title span {
            font-family: "proxima-nova";
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 24px;
            display: block;
            font-weight: bold;
        }
        .block-content.toggle-content {
            margin-top: 0;
        }
        .last.odd {
            padding: 0;
            border: none;
        }
        li {
            padding-bottom: 26px;
            a {
                color: $darkslategrey;
                padding: 0;
                font-family: "proxima-nova";
                font-size: 14px;
            }
        }
    }
    .col-main {
        width: 80.63333%;
        position: relative;
        min-height: 1px;
        float: left;
        margin-top: 60px;
        padding: 0;
        &.columns {
            @include respond-to(tablet) {
                width: 100%;
            }
            @include respond-to(mobile) {
                margin-top: 0;
                width: 100%;
            }
        }
    }
    .products-grid {
        margin: 0 auto;
        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .grid-item {
        width: 363px;
        margin-bottom: 20px;
        @include respond-to(tablet) {
            width: 250px;
        }
        @include respond-to(mobile) {
            width: 310px;
        }
    }
    .box-feature {
        position: relative;
        &__text {
            width: 100%;
            .actions {
                position: absolute;
                bottom: 10px;
                right: 10px;
                @include respond-to(mobile) {
                    right: 20px;
                }
            }
            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .title object {
                width: 60%;
                text-align: center;
            }
            .title a, .price {
                color: $gunpowder;
            }
            .title a {
                margin-bottom: 0;
                font-size: 12px;
                font-weight: bold;
            }
            .price {
                font-size: 16px;
                margin: 7px 0;
                .old-price {
                    .price {
                        font-size: 14px;
                    }
                }
            }
            .button {
                background: transparent;
                padding: 0;
                margin-bottom: 0;
                line-height: 12px;
                z-index: 50;
                position: absolute;
                right: 8px;
                bottom: 8px;
                [class*='icon-'] {
                    font-size: 26px;
                    color: $gunpowder;
                }
            }
        }
    }
    .products-grid .product-image img {
        max-width: none;
        width: auto;
    }
    .product-image {
        overflow: hidden;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .price {
        .special-price, .old-price {
            display: inline-block;
        }
    }
}

/**
* -----------------------------------------------------------------------
*                           Single Product Page
* -----------------------------------------------------------------------
*/
.product-name h1 {
    font-family: "proxima-nova";
    font-size: 32px;
    text-transform: inherit;
    font-weight: bold;
    line-height: 32px;
    color: $black;
    padding: 0;
}

.product-essential form {
    display: flex;
    @include respond-to(tablet) {
        flex-direction: column;
    }
    @include respond-to(mobile) {
        flex-direction: column;
    }
}

.product-spacing {
    padding: 50px 25px 0;
    @include respond-to(desktop) {
        padding-right: 350px;
    }
    @include respond-to(1700) {
        padding-left: 95px;
        padding-right: 250px;
    }
    @include respond-to(1200) {
        padding-right: 50px;
        padding-left: 50px;
    }
    .extra-info span {
        font-family: "proxima-nova";
        font-size: 12px;
        line-height: 16px;
        color: $turquoise;
        text-transform: capitalize;
    }
}

.product-img-box {
    flex: 1;
    @include respond-to(tablet) {
        width: 100%;
    }
    @include respond-to(mobile) {
        width: 100%;
    }
    .more-views {
        @include respond-to(992) {
            padding-left: 40px;
        }
    }
    .product-image img {
        width: 100%;
        @include respond-to(tablet) {
            margin: auto;
            width: auto;
            float: none;
        }
        @include respond-to(mobile) {
            margin: auto;
            width: auto;
            float: none;
        }
    }
}

.product-view {
    .product-shop {
        flex: 1;
        @include respond-to(tablet) {
            width: 100%;
        }
        @include respond-to(mobile) {
            width: 100%;
        }
        dl {
            margin-bottom: 0;
        }
        .tab-container, table {
            width: 100%;
            margin-bottom: 15px;
        }
        .label {
            padding: 0 0 16px;
            text-transform: uppercase;
        }
        table {
            border-top: 1px solid $whitesmoke;
            border-bottom: 1px solid $whitesmoke;
            tr td {
                font-family: "proxima-nova";
                font-size: 12px;
                line-height: 12px;
                color: $gunpowder;
                padding: 0 0 16px;
            }
            .first {
                .label, .data {
                    padding-top: 16px;
                }
            }
        }
    }
}

.product-info {
    margin: 50px 0 63px;
    @include respond-to(mobile) {
        padding: 0 25px;
    }
    .std {
        max-width: 744px;
        margin: 0 auto;
        p {
            margin: 0;
            font-family: "chaparral-pro";
            font-size: 16px;
            text-align: center;
            line-height: 24px;
            color: $gunpowder;
        }
    }
    h3 {
        font-family: "proxima-nova";
        text-transform: inherit;
        font-size: 32px;
        text-align: center;
        line-height: 32px;
        margin-bottom: 20px;
        font-weight: bold;
        color: $black;
    }
    .notice {
        font-style: italic;
        color: $gunpowder;
    }
}

.product-slider {
    margin-top: 63px;
    h4 {
        font-family: "proxima-nova";
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        color: $darkgray-nav;
        margin-bottom: 30px;
        text-transform: inherit;
    }
    img {
        min-height: 288px;
    }
}

.product-carousel {
    margin-bottom: 130px;

    .box-feature {
        position: relative;
        height: 288px;
        max-width: 100%;
        .title, .price {
            color: $gunpowder;
        }

        & > a {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .title {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: bold;
        }
        .price {
            font-size: 12px;
            text-align: left;
            font-weight: normal;
            line-height: 14px;
        }
        img {
            height: 100%;
            left: 50%;
            max-width: none;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: auto;
        }
        &__text {
            padding: 16px;
            position: absolute;
            top: 0;
            z-index: 1;
        }
        &__button {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40px;
            height: 40px;
            span {
                position: absolute;
                right: 8px;
                bottom: 8px;
            }
            &:hover, &:active {
                background-color: $turquoise;
                border-radius: 5px 0 0 0;
                span {
                    &::before {
                        color: $white;
                    }
                }
            }
            [class*='icon-'] {
                font-size: 26px;
                color: $gunpowder;
            }
        }
    }
}

.price-info {
    float: left;
    span {
        font-family: "proxima-nova";
        font-size: 30px;
        font-weight: 800;
        line-height: 32px;
        color: $darkgray-nav;
        letter-spacing: .03em;
        margin-bottom: 5px;
        display: block;
    }
}

.product-spacing .collateral-tabs {
    width: 290px;
    @include respond-to(tablet) {
        width: 100%;
    }
    @include respond-to(mobile) {
        width: 100%;
    }
}

.short-description {
    width: 290px;
    @include respond-to(tablet) {
        width: 100%;
    }
    @include respond-to(mobile) {
        width: 100%;
    }
    .std {
        font-family: "chaparral-pro";
        font-size: 16px;
        line-height: 24px;
        color: $gunpowder;
    }
    .tab span {
        display: none;
    }
}

.product-name {
    width: 290px;
    @include respond-to(tablet) {
        width: 100%;
    }
    @include respond-to(mobile) {
        width: 100%;
    }
}

.table tr td {
    color: $whitesmoke;
}

// Qunatity Button
.product-view .add-to-cart-wrapper {
    width: 100%;
    padding-left: 0;
}

.input-number {
    width: 80px;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
}

.input-number,
.input-number-decrement,
.input-number-increment {
    height: 40px;
    user-select: none;
}

.input-number-decrement,
.input-number-increment {
    width: 32px;
    height: 32px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    border: 2px solid $darkgray-nav;
    border-radius: 50px;
    color: $darkgray-nav;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-number-decrement:active,
.input-number-increment:active {
    background: #dddddd;
}

.product-view .add-to-cart .qty-wrapper {
    width: auto;
    margin-bottom: 16px;
    min-height: auto;
    margin-right: 0;
    .qty {
        &.input-number {
            border: none;
            box-shadow: none;
            background-color: $gainsboro;
            border-radius: 50px;
            width: 32px;
            height: 32px;
            margin: 0 8px;
            font-family: "proxima-nova";
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            line-height: 16px;
            color: $gunpowder;
        }
    }
}

.product-view {
    .add-to-box {
        display: flex;
    }
    .add-to-links {
        clear: inherit;
        margin-bottom: 0;
        align-self: flex-end;
        border: 2px solid $darkgray-nav;
        border-radius: 50px;
        margin-left: 11px;
        a {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            color: $darkgray-nav;
            font-size: 24px;
        }
    }
    .add-to-cart {
        max-width: 170px;
        float: left;
        &-buttons {
            margin-right: 0;
            .button {
                background-color: transparent;
                border: 2px solid $turquoise;
                color: $turquoise;
                border-radius: 50px;
                font-family: "proxima-nova";
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                line-height: 16px;
                padding: 0 16px;
                margin-bottom: 0;
            }
        }
    }
}

/**
* -----------------------------------------------------------------------
*                                Cart Page
* -----------------------------------------------------------------------
*/
.cart.display-single-price {
    padding: 0 15px;

    .product-cart-remove {
        width: 25px;
    }

    .button2 span,
    .button2 span span {
        color: $turquoise;
        &:hover {
            background-color: transparent;
        }
    }

    .button2 {
         &:hover {
            background-color: transparent;
            color: $turquoise;
        }
    }

    a:hover {
        color: $turquoise-dark;
    }

    .summary-collapse:before {
        border-top-color: $turquoise !important;
    }

    .crosssell h2,
    .cart-links a  {
        color: $turquoise;
    }

    .page-title.title-buttons {
        padding-top: 25px;
    }
    .product-cart-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        @include respond-to(mobile) {
            justify-content: flex-end;
        }
        .input-text.qty {
            margin: 0;
        }
    }
    #discount-coupon-form {
        .field-wrapper {
            display: flex;
        }
    }
    .discount-form {
        display: flex;
        align-items: center;
    }
    #discount-coupon-form .input-text, .cart .giftcard .input-text {
        margin: 0 25px 0 15px;
        @include respond-to(mobile) {
            margin: 0 10px 0 0;
        }
    }
    .success-msg {
        ul {
            margin: 0;
        }
    }
    .shipping {
        select, .form-list input {
            height: auto;
        }
        .form-list input {
            margin-top: 4px;
        }
        input[type=text] {
            padding: 8px;
        }
    }
    #shopping-cart-totals-table {
        tr {
            background: #f4f4f4;

        }
    }
}

.checkout-cart-index {
    .crosssell {
        width: 100%;

        .item {
            height: 411px;
            margin-bottom: 16px;
        }

        .product-info {
            display: flex;
            flex-direction: column;
            align-items: center;

            .actions {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
            }
        }

        .price {
            margin-bottom: 8px;
        }
    }

    #crosssell-products-list {
        display: flex;
        height: auto !important;
        flex-wrap: wrap;
    }
}

.messages {
    .success-msg {
        li {
            background-color: transparent;
            color: $turquoise;
        }
    }
}
.cart .cart-totals table {
    font-family: "proxima-nova";
    tfoot {
        td {
            padding: 8px;
        }
        strong {
            line-height: 1.2;
        }
    }
}

.price-box .price, .price {
    font-family: "proxima-nova";
}

.button2 span, .button2 span span {
    font-family: "proxima-nova";
}

.cart-table {
    thead th, tbody td {
        font-family: "proxima-nova";
    }
    .product-cart-sku {
        font-family: "proxima-nova";
    }
    .product-cart-image {
        @include respond-to(mobile) {
            width: 100%;
        }
    }
    .product-cart-info, .cart-table td[data-rwd-label] {
        @include respond-to(mobile) {
            width: 100%;
        }
    }
    .product-cart-info, td[data-rwd-label] {
        @include respond-to(mobile) {
            width: 100%;
        }
    }
    .cart-links a {
        font-size: 16px;
    }
}

.display-single-price .cart-forms, .display-single-price .cart-totals-wrapper, .display-single-price .crosssell {
    @include respond-to(mobile) {
        width: 100%;
    }
}

.display-single-price .cart-forms {
    @include respond-to(mobile) {
        padding-right: 0;
    }
}

#discount-coupon-form .discount {
    margin-bottom: 0;
    border-bottom: 0;
}

.display-single-price .cart-totals-wrapper, .display-single-price .crosssell {
    @include respond-to(mobile) {
        padding-left: 0;
    }
}

#discount-coupon-form .input-text, .cart .giftcard .input-text {
    @include respond-to(tablet) {
        width: 100%;
    }
    @include respond-to(mobile) {
        width: 100%;
    }
}

.cart .page-title h1 {
    @include respond-to(mobile) {
        float: none;
        margin-right: 0;
        text-align: center;
        margin-bottom: 15px;
    }
}

// Account Login
.account-login {
    padding: 25px 15px;
    
    .fieldset p.required {
        float: none;
    }
}
