a {
    &:hover, &:focus {
        text-decoration: none;
        outline: none;
    }
}
button, .button{
    &:hover, &:focus, &:active{
        outline: none;
    }

}
.menu-icon {
    &:hover,&:active,&:focus{
        outline: none;
    }
}
ul.off-canvas-list li a{
    &:hover,&:active,&:focus{
        background: transparent;
        outline: none;
    }
}
